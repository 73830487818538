import React from 'react';
import {FormattedMessage} from 'gatsby-plugin-intl';

import messages from './messages';
import {Container, Header, Wrapper, Title} from './WorkshopFeedback.styles';
import feedbackImgAxon from '../../../static/workshop-feedback-axon.png';
import feedbackImgDDD from '../../../static/workshop-feedback-ddd.png';

const TRAINING_TITLES = {
  cqrsAxon: 'CQRS and Event Sourcing Applied with Axon Framework',
  ddd: 'Domain-Driven Design Applied'
}

const WorkshopFeedback = () => (
  <Container>
    <Header><FormattedMessage {...messages.header}/></Header>
    <Wrapper>
      <Title>{TRAINING_TITLES.cqrsAxon}</Title>
      <img src={feedbackImgAxon} alt={`${TRAINING_TITLES.cqrsAxon} - feedback`}/>
    </Wrapper>
    <Wrapper>
      <Title>{TRAINING_TITLES.ddd}</Title>
      <img src={feedbackImgDDD} alt={`${TRAINING_TITLES.cqrsAxon} - feedback`}/>
    </Wrapper>
  </Container>
);

export default WorkshopFeedback;

