import styled from 'styled-components';

import {MEDIA} from '../../constants';
import Container from '../Container';
import {SectionHeader} from '../Section';
import {getColor, getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const Wrapper = styled.div`
  background-color: ${getColor('basicWhite')};
`;

export const StyledHeader = styled(SectionHeader)`
  padding-top: 50px;
  padding-bottom: 25px;

  @media (min-width: ${MEDIA.MED}) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${getSpacing('basic')} ${getSpacing('sm')} ${getSpacing('xl')} ${getSpacing('sm')};

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('xl')} 0;
  }

  @media (min-width: ${MEDIA.MAX}) {
    padding: ${getSpacing('xl')} ${getSpacing('xl')};
  }
`;

export const Description = styled.p`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('xmd')};
  line-height: 1.5;
  width: 100%;

  @media (min-width: ${MEDIA.MIN}) {
    margin-left: ${getSpacing('xl')};
    width: 70%;
  }
`;

export const StyledLink = styled.a`
  display: inline-block;
  margin: 0 auto;

  @media (min-width: ${MEDIA.MIN}) {
  }
  
`;

export const StyledLogo = styled.img`
  width: 150px;
  
  @media (min-width: ${MEDIA.MED}) {
    width: 200px;
  }
`;

export const SeparatorLine = styled.hr`
  border-top: 1px solid ${getColor('backgroundGray')};
  width: 100%;
`;
