const scope = 'components.HeroSection';

export default {
  hello: {
    id: `${scope}.hello`,
    defaultMessage: 'Cześć, jestem Piotr Wyczesany',
  },
  valueProposition: {
    id: `${scope}.valueProposition`,
    defaultMessage: 'Pomagam zespołom programistycznym wypracować bardziej strategiczne podejście, koncentrując ich działania na głębokim zrozumieniu domeny biznesowej, w której pracują, zamiast skupiać się głównie na rozwiązaniach technicznych.',
  },
  contactMeButton: {
    id: `${scope}.contactMe`,
    defaultMessage: 'Skontaktuj się ze mną',
  },
}
