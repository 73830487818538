import React from 'react';
import {Helmet} from 'react-helmet';
import {string} from 'prop-types';
import {useIntl} from 'gatsby-plugin-intl';

import Layout from '../components/Layout';
import Container from '../components/Container';
import HeroSection from '../components/HeroSection';
import TrainingsInfo from '../components/TrainingsInfo';
import TrainingVideo from '../components/TrainingVideo';
import References from '../components/References';
import DDDKRK from '../components/DDDKRK';
import {HOME_PAGE_TITLE} from '../constants';
import messages from '../components/HeroSection/messages';

const Home = ({path}) => {
  const {formatMessage} = useIntl();

  return (
    <Layout path={path}>
      <Helmet>
        <title>
          {HOME_PAGE_TITLE}
        </title>
        <meta name='description' content={formatMessage(messages.valueProposition)}/>
      </Helmet>
      <HeroSection/>
      <TrainingsInfo/>
      <Container>
        <TrainingVideo/>
      </Container>
      <References/>
      <DDDKRK/>
    </Layout>
  )
}

Home.propTypes = {
  path: string.isRequired,
}

export default Home;
