const scope = 'components.References';

export default {
  header: {
    id: `${scope}.header`,
    defaultMessage: ' REFERENCJE',
  },
  ref_01: "This is something I should've written quite some time ago, since I already knew and witnessed Piotr's skills quite a few times. I recently facilitated an introductory training he did for Domain Driven Design in my company. He had excellent examples (most of them hands‑on), highlighted good practices, involved students and showcased not just where DDD is good, but also where you should not use it. His knowledge really shined through what he was doing.",
  ref_02: 'Taking part in Domain Driven Design training by Piotr was an eye-opening experience. Many exercises and ability to code many patterns allowed to get a grasp of presented theory. I will definitely use some of the modeling techniques presented and encourage others to try them. Piotr is extremely enthusiastic about his work which is infectious. His style was open and inviting to questions which were thoroughly answered. I certainly would recommend him for any training on this topic.',
}
