import React from 'react';
import {string} from 'prop-types';

import {Text, Author, Container, StyledImg} from './Reference.styles';
import messages from './messages';
import quoteImg from '../../../static/quote.svg'

const Reference = ({text, author}) => (
  <Container>
    <StyledImg src={quoteImg} alt={messages.imgAlt}/>
    <div>
      <Text>{text}</Text>
      <Author>- {author}</Author>
    </div>
  </Container>
);

Reference.propTypes = {
  text: string.isRequired,
  author: string.isRequired,
}

export default Reference;
