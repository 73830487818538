import React from 'react';
import {FormattedMessage} from 'gatsby-plugin-intl';

import Button from '../Button';
import {Wrapper, Content, Overlay, Hello, Header} from './HeroSection.styles';
import messages from './messages';

const HeroSection = () => (
  <Wrapper>
    <Content>
      <Hello><FormattedMessage {...messages.hello}/></Hello>
      <Header><FormattedMessage {...messages.valueProposition} /></Header>
      <div>
        <Button to={'/#contact'} text={<FormattedMessage {...messages.contactMeButton}/>} variant="green"/>
      </div>
    </Content>
    <Overlay/>
  </Wrapper>
)

export default HeroSection
