import styled from 'styled-components';

import Container from '../Container';
import {MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographySize, getTypographyWeight} from '../../theme/helpers';

export const Wrapper = styled.div`
  padding: ${getSpacing('xl')} 0;
  background-color: ${getColor('basicWhite')};

  @media (min-width: ${MEDIA.MED}) {
    padding: ${getSpacing('xxl')} 0;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  padding: ${getSpacing('md')} ${getSpacing('sm')};

  @media (min-width: ${MEDIA.MED}) {
    width: 32%;
    padding: 0;
  }
`;

export const ContactBox = styled.div`
  width: 100%;
  border: 2px solid ${getColor('brandGreen')};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${getSpacing('xl')};
  padding: ${getSpacing('md')} ${getSpacing('xl')};
  margin: 0 ${getSpacing('sm')};

  @media (min-width: ${MEDIA.MED}) {
    width: 30%;
    margin: 0;
  }
`

export const ContactHeader = styled.h3`
  font-weight: ${getTypographyWeight('normal')};
  color: ${getColor('brandBlue')};
  font-size: ${getTypographySize('xl')};
  padding-bottom: ${getSpacing('xl')};
  text-align: center;
`;
