import React from 'react';
import ReactPlayer from 'react-player';
import {FormattedMessage} from 'gatsby-plugin-intl';

import {SectionWrapper} from '../Section';
import {Header, PlayerWrapper} from './TrainingVideo.styles';
import messages from './messages';

const VIDEO_01_URL = 'https://www.youtube.com/watch?v=_UMcvbFJIkQ&t=111s'

const TrainingVideo = () => (
  <SectionWrapper>
    <Header><FormattedMessage {...messages.video_01_title}/></Header>
    <PlayerWrapper>
      <ReactPlayer
        url={VIDEO_01_URL}
        controls
        className='react-player'
        width="100%"
        height="100%"
      />
    </PlayerWrapper>
  </SectionWrapper>
);

export default TrainingVideo;
