const scope = 'components.TrainingsInfo';

export default {
  dddTrainingHeader: {
    id: `${scope}.dddTrainingHeader`,
    defaultMessage: 'Domain-Driven Design Applied',
  },
  dddTrainingDesc: {
    id: `${scope}.dddTrainingDesc`,
    defaultMessage: 'Dowiedz się jak poradzić sobie ze złożonością w Twojej domenie biznesowej. Poznaj strategiczne wzorce, które pomogą znaleźć najbardziej wartościowe części Twojego systemu, oraz zbudować niezależne zespoły pracujące nad nimi. Poznaj taktyczne wzorce, które ułatwią komunikację programistów i testerów z biznesem. Zastosuj zdobytą wiedzę od razu, bezpośrednio w kodzie.',
  },
  axonTrainingHeader: {
    id: `${scope}.axonTrainingHeader`,
    defaultMessage: 'CQRS and Event Sourcing Applied with Axon Framework'
  },
  axonTrainingDesc: {
    id: `${scope}.axonTrainingDesc`,
    defaultMessage: 'Dowiedz się dlaczego zdarzenia biznesowe są idealnym rozwiązaniem dla systemów informacyjnych. Wykorzystaj je, aby zautomatyzować Twoje procesy biznesowe w sposób prosty i przejrzysty zarówno dla programistów, jak i biznesu. Poznaj Axon Framework, który w naturalny sposób wspiera Command-Query Responsibility Segregation, oraz Event Sourcing w Javie, Scali i Kotlinie. Weź udział w wysoce interaktywnym warsztacie i zobacz jak wprowadzić architekturę CQRS/ES na bazie istniejącego klasycznego rozwiązania',
  },
  buttonMsg: {
    id: `${scope}.buttonMsg`,
    defaultMessage: 'Dowiedz się więcej'
  },
  contactMeHeader: {
    id: `${scope}.contactMeHeader`,
    defaultMessage: 'Szukasz szkolenia na inny temat?'
  },
  contactMeButton: {
    id: `${scope}.contactMeButton`,
    defaultMessage: 'Skontaktuj się ze mną'
  },
}
