import React from 'react';
import {FormattedMessage} from 'gatsby-plugin-intl';

import Reference from '../Reference';
import Container from '../Container';
import WorkshopFeedback from '../WorkshopFeedback';
import {REFERENCES} from './config';
import {SectionWrapper, SectionHeader} from '../Section';
import {Wrapper, ReferenceList, ReferenceWrapper} from './Referenes.styles';
import messages from './messages';

const References = () => (
  <Wrapper id="references">
    <SectionWrapper>
      <Container>
        <SectionHeader><FormattedMessage {...messages.header}/></SectionHeader>
        <ReferenceList>
          {REFERENCES.map(({text, author}, i) => (
            <ReferenceWrapper key={i}>
              <Reference
                key={i}
                text={text}
                author={author}
              />
            </ReferenceWrapper>
          ))}
        </ReferenceList>
        <WorkshopFeedback/>
      </Container>
    </SectionWrapper>
  </Wrapper>
);

export default References;
