import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Text = styled.p`
  padding-top: ${getSpacing('md')};
  font-size: ${getTypographySize('xmd')};
  color: ${getTypographyColor('brandBlue')};
  line-height: 1.5;
`;

export const Author = styled(Text)`
  padding-top: ${getSpacing('basic')};
`;

export const StyledImg = styled.img`
  margin-right: ${getSpacing('md')};
  display: none;

  @media (min-width: ${MEDIA.MIN}) {
    display: block;
    width: 40px;
    height: 29px;
  }

  @media (min-width: ${MEDIA.MED}) {
    width: 72px;
    height: 52px;
  }
`;
