import messages from './messages';

export const REFERENCES = [
  {
    text: messages.ref_01,
    author: 'Tomek Borek',
  },
  {
    text: messages.ref_02,
    author: 'Michał Łosiewicz',
  },
]
