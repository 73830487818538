import styled from 'styled-components';

import {getTypographySize, getSpacing, getTypographyColor, getTypographyWeight} from '../../theme/helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Header = styled.h3`
  color: ${getTypographyColor('brandBlue')};
  font-size: ${getTypographySize('xl')};
  padding-bottom: ${getSpacing('xmd')};
  font-weight: ${getTypographyWeight('normal')};
  flex-grow: 1;
`;

export const Text = styled.p`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('basic')};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.5;
`;

export const ButtonWrapper = styled.div`
  padding: ${getSpacing('xl')} 0
`;
