import React from 'react';
import {FormattedMessage} from 'gatsby-plugin-intl';

import {TRAININGS} from './config';
import messages from './messages';
import {Wrapper, StyledContainer, BoxWrapper, ContactBox, ContactHeader} from './TrainingsInfo.styles';
import TextBoxWithButton from '../TextBoxWithButton';
import Button from '../Button';

const TrainingsInfo = () => (
  <Wrapper id="trainings">
    <StyledContainer>
      {TRAININGS.map(({header, description, slug}) => (
        <BoxWrapper key={slug}>
          <TextBoxWithButton
            header={<FormattedMessage {...header} />}
            text={<FormattedMessage {...description} />}
            buttonLink={`/trainings/${slug}`}
            buttonMsg={<FormattedMessage {...messages.buttonMsg} />}
          />
        </BoxWrapper>
      ))}
      <ContactBox>
        <ContactHeader><FormattedMessage {...messages.contactMeHeader}/></ContactHeader>
        <Button to="/#contact" text={<FormattedMessage {...messages.contactMeButton}/>}/>
      </ContactBox>
    </StyledContainer>
  </Wrapper>
);

export default TrainingsInfo;
