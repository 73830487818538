import React from 'react';
import {useIntl, FormattedMessage} from 'gatsby-plugin-intl';

import messages from './messages';
import {
  StyledContainer,
  Wrapper,
  Description,
  StyledLogo,
  SeparatorLine,
  StyledHeader,
  StyledLink
} from './DDDKRK.styles';
import dddLogoImg from '../../../static/ddd_krk.svg';

const DDDKRK_LINK = 'https://www.meetup.com/DDD-KRK/';

const DDDKRK = () => {
  const {formatMessage} = useIntl();

  return (
    <Wrapper>
      <StyledContainer>
        <SeparatorLine/>
        <StyledHeader><FormattedMessage {...messages.header}/></StyledHeader>
        <StyledLink
          href={DDDKRK_LINK}
          target="_blank"
          rel="noopener noreferrer">
          <StyledLogo src={dddLogoImg} alt={formatMessage(messages.logoAlt)}/>
        </StyledLink>
        <Description>
          <FormattedMessage {...messages.description}/>
        </Description>
      </StyledContainer>
    </Wrapper>
  );
}

export default DDDKRK;



