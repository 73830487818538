const scope = 'components.DDDKRK';

export default {
  header: {
    id: `${scope}.header`,
    defaultMessage: 'DDD-KRK MEETUP',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Grupa dla osób zainteresowanych nauką i zastosowaniem Domain-Driven Design. Chcemy dzielić się wiedzą, pomysłami i doświadczeniem. Chcemy też promować DDD w Krakowie. Dołącz do nas, aby omówić korzyści, jakie daje DDD.',
  },
  logoAlt: {
    id: `${scope}.logoAlt`,
    defaultMessage: 'ddd-krk-logo',
  },
}
