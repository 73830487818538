import styled from 'styled-components';

import {getColor, getSpacing, getTypographySize, getTypographyWeight} from '../../theme/helpers';

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Header = styled.h4`
  font-weight: ${getTypographyWeight('semiBold')};
  font-size: ${getTypographySize('md')};
  padding-bottom: ${getSpacing('md')};
  color: ${getColor('brandBlue')};
`;
