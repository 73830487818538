import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getSpacing, getColor} from '../../theme/helpers';

export const Wrapper = styled.div`
  background-color: ${getColor('basicWhite')};
`;

export const ReferenceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: ${getSpacing('xl')} 0;
`;

export const ReferenceWrapper = styled.div`
  width: 100%;
  padding-bottom: ${getSpacing('lg')};

  @media (min-width: ${MEDIA.MIN}) {
    width: 45%;
    padding-bottom: 0;

    &:first-of-type {
      margin-right: ${getSpacing('md')};
    }
  }

  @media (min-width: ${MEDIA.MED}) {
    width: 35%;
  }
`
