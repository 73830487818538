import messages from './messages';

export const TRAININGS = [
  {
    header: messages.dddTrainingHeader,
    description: messages.dddTrainingDesc,
    slug: 'domain-driven-design-applied',
  },
  {
    header: messages.axonTrainingHeader,
    description: messages.axonTrainingDesc,
    slug: 'cqrs-event-sourcing-axon',
  },
]
