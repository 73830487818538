import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getSpacing, getTypographyColor, getTypographySize, getTypographyWeight} from '../../theme/helpers';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Header = styled.h4`
  font-size: ${(getTypographySize('lg'))};
  font-weight: ${getTypographyWeight('bold')};
  padding: ${getSpacing('md')} 0;
  color: ${getTypographyColor('brandBlue')};
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: ${getSpacing('md')};
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIA.MIN}) {
    width: 40%;
    padding-right: ${getSpacing('md')};
    padding-bottom: 0;
  }
`;

export const Title = styled.h5`
  font-size: ${(getTypographySize('xmd'))};
  padding-top: ${getSpacing('md')};
  padding-bottom: ${getSpacing('xl')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('bold')};
  flex-grow: 1;
`;
