import styled from 'styled-components';
import {bool} from 'prop-types';

import {MEDIA} from '../../constants';
import {getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const SectionWrapper = styled.div`
  padding: ${getSpacing('xl')} ${getSpacing('sm')};

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('xxxl')} 0;
  }
`;

export const SectionHeader = styled.h3`
  color: ${getTypographyColor('brandGreen')};
  font-size: ${getTypographySize('xl')};
  width: ${({isFullWidth}) => isFullWidth ? '100%' : 'initial'};

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
  }
`;

SectionHeader.propTypes = {
  isFullWidth: bool,
};

SectionHeader.defaultProps = {
  isFullWidth: true,
}
