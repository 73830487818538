import styled from 'styled-components';

import heroImageSmall from '../../../static/hero-section-piotr-wyczesany.png';
import {MEDIA} from '../../constants';
import {getColor, getTypographySize, getSpacing, getTypographyWeight} from '../../theme/helpers';

export const Wrapper = styled.div`
  background: url(${heroImageSmall}) no-repeat center/cover;
  min-height: 425px;
  max-width: 2500px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;

  @media (min-width: ${MEDIA.MIN}) {
    min-height: 510px;
  }

  @media (min-width: ${MEDIA.MED}) {
    position: relative;
  }
`

export const Overlay = styled.div`
  background: transparent linear-gradient(283deg, #14397D 0%, #164BAC 100%);
  background-size: cover;
  height: 425px;
  width: 100%;
  max-width: 2500px;
  opacity: 0.7;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: ${MEDIA.MIN}) {
    min-height: 510px;
    top: 0;
  }
`

export const Content = styled.div`
  color: ${getColor('basicWhite')};
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  padding: 0 36px;
  width: 100%;

  @media (min-width: ${MEDIA.MED}) {
    padding: 0 ${getSpacing('md')};
  }

  @media (min-width: ${MEDIA.MAX}) {
    width: 100%;
    max-width: 1400px;
  }
`

export const Hello = styled.span`
  text-transform: uppercase;
  font-size: ${getTypographySize('lg')};
  margin-bottom: ${getSpacing('lg')};

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
  }
`

export const Header = styled.h1`
  max-width: 670px;
  line-height: 1.5;
  font-size: ${getTypographySize('basic')};
  font-weight: ${getTypographyWeight('light')};
  color: ${getColor('backgroundGray')};
  margin-bottom: 2em;

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('md')};
  }
`
