import React from 'react';
import {string, shape} from 'prop-types';

import {Wrapper, Header, Text, ButtonWrapper} from './TextBoxWithButton.styles';
import Button from '../Button';

const TextBoxWithButton = ({header, text, buttonMsg, buttonLink}) => (
  <Wrapper>
    <Header>{header}</Header>
    <Text>{text}</Text>
    <ButtonWrapper>
      <Button to={buttonLink} text={buttonMsg}/>
    </ButtonWrapper>
  </Wrapper>
);

TextBoxWithButton.propTypes = {
  header: shape({}).isRequired,
  text: shape({}).isRequired,
  buttonMsg: shape({}).isRequired,
  buttonLink: string.isRequired,
}

export default TextBoxWithButton;
